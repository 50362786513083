/* ComingSoon.css */

.coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    height: 100vh;
    background: linear-gradient(to right, #141e30, #243b55);
    color: white;
    font-family: Arial, sans-serif;
}

.logo img {
    width: 150px;
}
.company-name {
    @media (max-width: 550px) {
        width: 100%;
    }
}
/* İlk harfler için özel stil */
.company-name .first-letter {
    animation:
        fadeIn 0.5s forwards,
        colorChange 3.5s infinite ease-in-out;
}

/* Harf harf yazılma animasyonu */
.company-name span {
    display: inline-block;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    font-size: 50px;
    @media (max-width: 550px) {
        font-size: 25px;
    }
}
/* Boşluklar için özel sınıf */
.company-name .space {
    width: 0.5em; /* Boşluk için genişlik ayarı */
}
/* Renk geçişi animasyonu */
@keyframes colorChange {
    0% {
        color: #ffffff; /* Kırmızı */
    }
}
/* Harf animasyonu */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

p {
    font-size: 1.2rem;
    margin-top: 10px;
    a {
        color: white;
    }
}

/* Social links */
.social-links {
    margin-top: 20px;
    /* ComingSoon.css */

    /* Buton stili */
    .button {
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        font-size: 1rem;
        color: #00c6ff;
        text-decoration: none;
        font-weight: bold;
        background-color: transparent;
        border: 2px solid #00c6ff;
        border-radius: 5px;
        overflow: hidden;
        transition:
            color 0.3s,
            border-color 0.3s;
    }

    /* Border animasyonu */
    .button::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #00c6ff;
        transform: scaleX(0);
        transform-origin: bottom right;
        animation: borderAnimation 0.5s forwards;
    }

    /* Border animasyon */
    @keyframes borderAnimation {
        0% {
            transform: scaleX(0);
            transform-origin: bottom left;
        }
        50% {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
        100% {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    }

    /* Hover durumunda buton rengi değişsin */
    .button:hover {
        color: #fff;
        border-color: #fff;
    }

    .button:hover::before {
        background-color: #fff;
        animation: borderAnimationReverse 0.5s forwards;
    }

    /* Border animasyonunun tersine döneceği keyframes */
    @keyframes borderAnimationReverse {
        0% {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
        50% {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
        100% {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    }
}

.social-links a {
    color: #00c6ff;
    text-decoration: none;
    font-size: 1.1rem;
    margin: 0 10px;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #fff;
}
